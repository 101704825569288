import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import {
  GenerateInvoicesParameters,
  GetTripProductsToInvoice,
  GetTripProductsToInvoiceParameters,
  InvoiceType,
  TripProductToInvoice,
} from '../../../models/Invoice';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Title } from '../../../stories/dune/atoms/Title';
import { Button } from '../../../stories/dune/atoms/Button';
import { throttle } from 'lodash';
import moment from 'moment';

interface ListInvoicesProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
  onSuccess?: () => void;
}

const ListInvoices = React.memo((props: ListInvoicesProps) => {
  // #region const init

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('invoiceDueDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetTripProductsToInvoice[]>([]);
  const [selectedRows, setSelectedRows] = useState<GetTripProductsToInvoice[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    // const url =
    //   orgid +
    //   '/trip/get-trip-products-to-invoice?page=' +
    //   page +
    //   '&limit=' +
    //   newPerPage +
    //   '&sortBy=' +
    //   newSortColumn +
    //   '&orderBy=' +
    //   newSortDirection;

    const url = orgid + '/invoice/get-trip-products-to-invoice?page=' + page + '&limit=' + newPerPage;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.get_API_EXTRANET_URL(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        if (getFiltersToSend(TabFilter, AdvancedFilters).buildingSiteId) {
          // TEMP TODO : correction de la route (DUNE-885)
          res.data.content.items.map(
            (x: GetTripProductsToInvoice) =>
              (x.tripProducts = x.tripProducts.filter(
                (y) => y.buildingSiteId == getFiltersToSend(TabFilter, AdvancedFilters).buildingSiteId,
              )),
          );
        }
        // res.data.content.items.map(
        //   (x: GetTripProductsToInvoice) => (x.tripProducts = x.tripProducts.filter((y) => y.unitPrice > 0)),
        // );
        // setFilteredItems(res.data.content.items.filter((x: GetTripProductsToInvoice) => getTotalAmount(x) > 0));
        setFilteredItems(res.data.content.items); // finalement pas de filtre sur le montant

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetTripProductsToInvoiceParameters = {};

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'product') toSend.productId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'dateStart')
          toSend.startDate = moment(x.filterData, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
        if (x.filterType === 'dateEnd') toSend.endDate = moment(x.filterData, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
      });
    }

    return toSend;
  }

  const seeDetails = (row: GetTripProductsToInvoice) => {
    // const url = 'invoices';
    // return `/${url}/${row.id}/update`;
    console.log('details of rows to invoice'); // TODO
    return '';
  };

  const columns: any[] = [
    {
      name: t('common.customer'),
      selector: (row: GetTripProductsToInvoice) => row.customer?.label,
      cell: (row: GetTripProductsToInvoice) => (
        <div className="column" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">
            <strong className="caption2medium" data-tag="allowRowEvents">
              {row.customer?.label}
            </strong>
          </div>
          <div data-tag="allowRowEvents">
            <i className="caption2medium" data-tag="allowRowEvents">
              {row.customer?.externalReference}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      omit: props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == 'customer') !== -1,
      id: 'customer',
      grow: 2,
    },
    {
      name: <>{t('common.amount') + ' (€)'}</>,
      selector: (row: GetTripProductsToInvoice) => getTotalAmount(row),
      cell: (row: GetTripProductsToInvoice) => (
        <div className="column" data-tag="allowRowEvents">
          <strong className="caption2medium" data-tag="allowRowEvents">
            {roundNumber(getTotalAmount(row) ?? 0, 2)}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'amount',
    },
    {
      name: t('common.trips'),
      selector: (row: GetTripProductsToInvoice) => getTripsCount(row),
      cell: (row: GetTripProductsToInvoice) => (
        <div className="column" data-tag="allowRowEvents">
          <strong className="caption2medium" data-tag="allowRowEvents">
            {getTripsCount(row)}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'trips',
    },
    {
      name: t('common.buildingSites'),
      selector: (row: GetTripProductsToInvoice) => getBuildingSitesCount(row),
      cell: (row: GetTripProductsToInvoice) => (
        <div className="column" data-tag="allowRowEvents">
          <strong className="caption2medium" data-tag="allowRowEvents">
            {getBuildingSitesCount(row)}
          </strong>
        </div>
      ),

      sortable: false,
      right: false,
      id: 'buildingSites',
    },
  ];

  const getTotalTripProductPrice = (tripProduct: TripProductToInvoice) => {
    return (
      tripProduct.deliveredQuantity *
      ((1 + tripProduct.vatRate) * (tripProduct.repValue + tripProduct.tgapValue + tripProduct.unitPrice))
    );
  };

  const getTotalAmount = (customerTripProducts: GetTripProductsToInvoice) => {
    return customerTripProducts.tripProducts.reduce((a, b) => a + getTotalTripProductPrice(b), 0);
  };

  const getTripsCount = (customerTripProducts: GetTripProductsToInvoice) => {
    const tripIdArray = customerTripProducts.tripProducts.map((x) => x.tripId);
    return tripIdArray.filter((value, index, array) => array.indexOf(value) === index).length;
  };

  const getBuildingSitesCount = (customerTripProducts: GetTripProductsToInvoice) => {
    const buildingSiteIdArray = customerTripProducts.tripProducts.map((x) => x.buildingSiteId);
    return buildingSiteIdArray.filter((value, index, array) => array.indexOf(value) === index).length;
  };

  function roundNumber(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  // #endregion specific

  // #endregion const init

  // #region event handling

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleGenerateInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/invoice/generate-from-trip-products';

    const dataToSend: GenerateInvoicesParameters = {
      invoiceType: InvoiceType.PROFORMA,
      tripProducts: selectedRows.map((x) => x.tripProducts.map((y) => y.id)).flat(),
    };

    axios
      .post(
        Config.get_API_EXTRANET_URL(url),
        { ...dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.invoices.length > 1) {
          addToast(
            t('common.multipleProformaGenerated').replace('¤', res.data.content.invoices.length),
            ToastTypes.success,
          );
          history('/invoicing/invoices/' + InvoiceType.PROFORMA);
          if (props.onSuccess) props.onSuccess();
        } else if (res.data.content.invoices.length === 1) {
          addToast(t('common.proformaGenerated'), ToastTypes.success);
          history('/invoices/' + res.data.content.invoices[0] + '/update');
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  const throttledhandleGenerateInvoice = throttle(handleGenerateInvoice, 1000);

  function handleRowSelected(selectedRows: any) {
    setSelectedRows(selectedRows.selectedRows);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={false}
        allowAdvancedSearch={true}
        advancedSearchOptions={['dateStart', 'dateEnd', 'customer', 'buildingSite', 'product']}
        showAddNew={false}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['product']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          <FormRow align="left">
            <Title
              type="label"
              label={
                selectedRows.length === 0
                  ? filteredItems.length + ' ' + t('common.customers').toLowerCase()
                  : selectedRows.length === 1
                  ? t('common.oneCustomerSelected')
                  : t('common.multipleCustomersSelected').replace('¤', selectedRows.length.toString())
              }
              width="auto"
            />
            {selectedRows.length > 0 && (
              <Button onClick={() => throttledhandleGenerateInvoice()} label={t('common.invoiceAction')} />
            )}
          </FormRow>
          <DataTable
            className="table"
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            // onRowClicked={(row) => history(seeDetails(row))}
            // highlightOnHover
            // pointerOnHover
            data={filteredItems}
            noDataComponent={<div className="noResults">{t('common.noResult')}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t('common.perPage'),
              rangeSeparatorText: t('common.onPage'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All',
            }}
            dense
            selectableRows
            onSelectedRowsChange={handleRowSelected}
          />
        </>
      )}
    </>
  );
});

export default ListInvoices;
